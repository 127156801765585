// i18next-extract-mark-ns-start markets-motorcycle-friction

import { MARKETS_CAPABILITIES_EFFICIENCY_NAV, MOTORCYCLE_NAV, EFFICIENCY_PRODUCTS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorcyclePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const mech = getImage(data.mech.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Cutting propulsion friction losses </h2>
						<p>
							Predicting the friction losses of combustion engines is a crucial topic for today’s engine developments
							which are under ever mounting pressures to find the most cost effective approach to improve fuel economy
							and overall mechanical efficiency.
							Engine friction plays a significant part in a vehicle’s efficiency,
							but it is difficult to calculate precisely and hard to measure confidently.
							Manufacturers are needed to do more analysis with less engineering resource available in this area.
						</p>
						<p>
							The Realis integrated friction solution offers a complete toolset allowing users to assess trade-off of design changes on other constraints,
							including NVH and durability. Helping to minimise friction and improve engine efficiency.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) tool that includes a predictive friction module called FAST,
							that enables the rapid estimation of cycle averaged friction to assess the significant contributors
							to total Internal Combustion Engine (ICE) Friction Mean Effective Pressure (FMEP), together with other types of losses.
						</p>
					</Trans>

					{mech && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={mech}
								alt="Structual Mechanics"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_3">
						<p>
							The structural mechanics suite, including ENGDYN, PISDYN, RINGPAK, SABR, and VALDYN,
							are higher fidelity tools complementary to FAST, that predict ICE friction (FMEP) and transmission efficiencies
							for detailed component design and as input to a vehicle system model to assess drive cycle energy consumption.
						</p>
					</Trans>

					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_4">
						<p>
							IGNITE is a complete physics-based simulation package. With a library system of building blocks,
							the user can quickly and accurately model vehicle systems, to predict vehicle drive cycle energy consumption.
						</p>
					</Trans>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORCYCLE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{EFFICIENCY_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorcyclePage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorcycle-friction", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_1345_motorcycleEngine.jpg" }) {
			...imageBreaker
		}
		ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
			...imageBreaker
		}
		mech: file(relativePath: { eq: "content/StructuralMechanics_notxt.png" }) {
			...imageBreaker
		}
		wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Motorcycle" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
